import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Modal from '../Modal/Modal';
import Swal from 'sweetalert2';
import '../Price/Price.css';

const Price = () => {
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({ name: '', email: '', mobile: '', query: '' });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const handleShowModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case 'name':
        errors.name = /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets.';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address.';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits.';
        break;
      case 'query':
        errors.query = value.length >= 15 ? '' : 'Query should be at least 15 characters long.';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Object.keys(formValues).forEach((name) => validate(name, formValues[name]));

    if (Object.values(formErrors).some((error) => error !== '')) {
      setSubmissionError('Please fix the errors in the form.');
      return;
    }

    setSubmitting(true);

    emailjs.send('service_xfjuzxz', 'template_fmeei2f', formValues, '7whvvYJEvhwHWK7Mn')
      .then((response) => {
        Swal.fire('Success', 'Email sent successfully!', 'success');
        setSubmitting(false);
        setShowModal(false);
        setFormValues({ name: '', email: '', mobile: '', query: '' });
        setFormErrors({});
        setSubmissionError('');
      })
      .catch((error) => {
        console.log('FAILED...', error);
        setSubmitting(false);
        setSubmissionError('Failed to send email. Please try again.');
        Swal.fire('Error', 'Failed to send email. Please try again later.', 'error');
      });
  };

  return (
    <>
      <div className="price-section">
        <div className="left-p">
          <h2 className="price-heading">Property Prices for JKG Palm Court</h2>
          <p className="price-subheading">Find the perfect home for your needs</p>
        </div>
        <div className="right-p">
          <h3 className="price-list-heading">Price List JKG Palm Court</h3>
          <div className="price-list">
            <div className="price-card">
              <h4>2 BHK + 2 Toilets</h4>
              <p>Area: 101.26 Sq.Mt. (1090 Sq.ft.)</p>
              <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span>
            </div>
            <div className="price-card">
              <h4>2 BHK + 2 Toilets + Store</h4>
              <p>Area: 110.09 Sq.Mtr. (1185 Sq.ft.)</p>
              <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span>
            </div>
            <div className="price-card">
              <h4>3 BHK + 2 Toilets</h4>
              <p>Area: 129.60 Sq.Mt.(1395 Sq.ft.)</p>
              <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span>
            </div>
            <div className="price-card">
              <h4>3 BHK + 2 Toilets</h4>
              <p>Area: 141.21 Sq.Mt.(1520 Sq.ft.)</p>
              <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span>
            </div>
            <div className="price-card">
              <h4>3 BHK + 3 Toilets</h4>
              <p>Area: 151.90 Sq.Mt.(1635 Sq.ft.)</p>
              <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span>
            </div>
            <div className="price-card">
              <h4>3 BHK + 3 Toilets + Dress</h4>
              <p>Area: 163.97 Sq.Mt.(1765 Sq.ft.)</p>
              <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span>
            </div>
            <div className="price-card">
              <h4>3 BHK + 3 Toilets + Dress + Study</h4>
              <p>Area: 186.27 Sq.Mt.(2005 Sq.ft.)</p>
              <span className="enquire-link" onClick={handleShowModal}>Price on Demand</span>
            </div>
          </div>
        </div>
        <Modal show={showModal} handleClose={hideModal}>
          <h2 className="modal-heading">Get More Details</h2>
          <form className="modal-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                className={`input ${formErrors.name ? 'invalid' : ''}`}
                required
              />
              {formErrors.name && <span className="error">{formErrors.name}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                className={`input ${formErrors.email ? 'invalid' : ''}`}
                required
              />
              {formErrors.email && <span className="error">{formErrors.email}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile:</label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                value={formValues.mobile}
                onChange={handleChange}
                className={`input ${formErrors.mobile ? 'invalid' : ''}`}
                required
              />
              {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="query">Your Query:</label>
              <textarea
                id="query"
                name="query"
                value={formValues.query}
                onChange={handleChange}
                className={`input ${formErrors.query ? 'invalid' : ''}`}
                rows="4"
                required
              />
              {formErrors.query && <span className="error">{formErrors.query}</span>}
            </div>
            <div className="butt-f">
              <button className="button-3" type="submit" disabled={submitting}>
                {submitting ? 'Submitting...' : 'SUBMIT'}
              </button>
            </div>
            {submissionError && <p className="submission-error">{submissionError}</p>}
          </form>
        </Modal>
      </div>
    </>
  );
};

export default Price;
