import React from 'react';
import { FaPhone } from 'react-icons/fa';
import '../Location/Location.css';

const Location = () => {
  return (
    <>
    <div className='full-l'>
         <h1 className='heading-l'>Luxury JKG Location Map</h1> 
   
    <div className="location-section">
  
      <div className="L-l">
        <img src="location.jpg" alt="Location" />
      </div>
      <div className="L-r">
        <h2>JKG Palm Court</h2>
        <h5>GH-1D,Sector-16C, Greater Noida West<br />
           </h5>
        <a href="tel:+917303734500" className="call-now">
          <FaPhone className="call-icon" />
          <span>Call Now</span>
        </a>
      </div>
    </div>
    </div>
    </>
  );
};

export default Location;
