import React, { useState } from "react";
import "../Gallery/Gallery.css";

const Gallery = () => {
  const listOneImages = [
    "g1.jpg",
    "g2.jpg",
    "g3.jpg",
    "g4.jpg",
    "g5.jpg",
    "g6.jpg",
    // add more images if needed
  ];

  const listTwoImages = [
    "g7.jpg",
    "g8.jpg",
    "g9.jpg",
    "g10.jpg",
    "g11.jpg",
    "g12.jpg",
    // add more images if needed
  ];

  // State to handle the selected image for full-screen view
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image click and set the selected image
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // Function to close the full-screen overlay
  const closeOverlay = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery">
      <h2 className="gallery-heading">Our Gallery</h2>

      <div className="image-list image-list-one">
        <div className="image-track">
          {[...listOneImages, ...listOneImages].map((image, index) => (
            <div
              className="image-item"
              key={index}
              onClick={() => handleImageClick(image)}
            >
              <img src={image} alt={`image-${index}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="image-list image-list-two">
        <div className="image-track">
          {[...listTwoImages, ...listTwoImages].map((image, index) => (
            <div
              className="image-item"
              key={index}
              onClick={() => handleImageClick(image)}
            >
              <img src={image} alt={`image-${index}`} />
            </div>
          ))}
        </div>
      </div>

      {/* Full-Screen Overlay */}
      {selectedImage && (
        <div className="overlay3">
          <div className="overlay-content3">
            <span className="close-btn3" onClick={closeOverlay}>
              &times;
            </span>
            <img src={selectedImage} alt="Selected" className="overlay-image3" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
