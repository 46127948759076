import React, { useEffect } from 'react';
import '../Raw/Raw.css';
import { FaParking, FaSwimmingPool, FaWifi, FaDumbbell, FaConciergeBell, FaWind, FaCamera, FaKey, FaFireExtinguisher, FaBath, FaTint, FaPlug, FaTools, FaDoorClosed, FaBuilding, FaWater, FaPaintBrush } from 'react-icons/fa';
const Raw = () => {
  useEffect(() => {
    console.log('useEffect running');
    const cards = document.querySelectorAll('.card4');
    console.log('Cards found:', cards);
  
    cards.forEach((card, index) => {
      const delay = index * 0.5; // Adjust delay for sequential animation
      console.log(`Applying animation to card ${index} with delay ${delay}`);
      card.style.animation = `changeColor 2s ease-in-out ${delay}s infinite, returnToWhite 2s ease-in-out ${delay + 2}s infinite`;
    });
  }, []);
  
  const amenities = [
    { name: 'Earthquake Resistant Structure', icon: <FaBuilding /> },
    { name: '24-Hour Water Supply', icon: <FaWater /> },
    { name: 'Modern External Finish', icon: <FaPaintBrush /> },
    { name: 'High-Quality Internal Finish', icon: <FaTools /> },
    { name: 'ISI Copper Wiring', icon: <FaPlug /> },
    { name: 'Hot & Cold Water in Bathrooms', icon: <FaTint /> },
    { name: 'European W.C. & Fittings', icon: <FaBath /> },
    { name: 'Granite Kitchen Platform with RO', icon: <FaConciergeBell /> },
    { name: 'Aluminum/UPVC Windows & Doors', icon: <FaDoorClosed /> },
    { name: 'Fire Safety', icon: <FaFireExtinguisher /> },
  ];
  
  
    return (
      <section className="raw">
      <h2>Amenities</h2>
      <div className="card-grid4">
        {amenities.map((amenity, index) => (
          <div key={index} className="card4">
            <h1 className="card-title4">{amenity.name}</h1>
            <div className="card-icon4">{amenity.icon}</div> {/* Icon below the text */}
          </div>
        ))}
      </div>
    </section>
    );
  };
  
  export default Raw;