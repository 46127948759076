import React,{useState} from "react";
import "../Cards/Cards.css"; // Ensure this CSS file contains any additional styling you need

const cardsData = [
  {
    id: 1,
    imgSrc: "f1.jpg",
    name: "2 BHK + 2 Toilets",
    sale: "Saleable Area: 101.26 Sq.Mt. (1090 Sq.ft.)",
    backgroundImage:
      "f1.jpg",
  },
  {
    id: 2,
    imgSrc: "f2.jpg",
    name: "2 BHK + 2 Toilets + Store",
    sale: "Saleable Area: 110.09 Sq.Mtr. (1185 Sq.ft.)",
    backgroundImage:
      "f2.jpg",
  },
  {
    id: 3,
    imgSrc: "f3.jpg",
    name: "3 BHK + 2 Toilets",
    sale: "Saleable Area: 129.60 Sq.Mt.(1395 Sq.ft.)",
    backgroundImage:
      "f3.jpg",
  },
  {
    id: 4,
    imgSrc: "f4.jpg",
    name: "3 BHK + 2 Toilets",
    sale: "Saleable Area: 141.21 Sq.Mt.(1520 Sq.ft.)",
    backgroundImage:
      "f4.jpg",
  },
  {
    id: 5,
    imgSrc: "f5.jpg",
    name: "3 BHK + 3 Toilets",
    sale: "Saleable Area: 151.90 Sq.Mt.(1635 Sq.ft.)",
    backgroundImage:
      "f5.jpg",
  },
  {
    id: 6,
    imgSrc: "f6.jpg",
    name: "3 BHK + 3 Toilets + Dress",
    sale: "Saleable Area: 163.97 Sq.Mt.(1765 Sq.ft.)",
    backgroundImage:
      "f6.jpg",
  },
  {
    id: 7,
    imgSrc: "f7.jpg",
    name: "3 BHK + 3 Toilets + Dress + Study",
    sale: "Saleable Area: 186.27 Sq.Mt.(2005 Sq.ft.)",
    backgroundImage:
      "f7.jpg",
  },
  // Add more cards as needed
];

const Cards = () => {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const openOverlay = (image) => {
      setSelectedImage(image);
    };
  
    const closeOverlay = () => {
      setSelectedImage(null);
    };
  
    return (
      <section className="cards-section">
        <h1 className="section-heading2">Our Layouts</h1>
        <div className="cards-grid">
          {cardsData.map((card) => (
            <div
              className="card"
              key={card.id}
              style={{
                backgroundImage: `url(${card.backgroundImage})`,
              }}
              onClick={() => openOverlay(card.backgroundImage)}
            >
              <main>
                <img src={card.imgSrc} alt={card.name} />
                <h2 className="c-name">{card.name}</h2>
                <p className="c-sale">{card.sale}</p>
              </main>
            </div>
          ))}
        </div>
  
        {selectedImage && (
          <div className="overlay">
            <div className="overlay-content">
              <span className="overlay-close" onClick={closeOverlay}>×</span>
              <img src={selectedImage} alt="Selected" className="overlay-image" />
            </div>
          </div>
        )}
      </section>
    );
  };
  
  export default Cards;