import React from 'react'
import '../Layout/Layout.css'
import Cards from '../Cards/Cards'
import Plan from '../Plan/Plan'
const Layout = () => {
  return (
    <>
     <div className='cardd'>
<Cards/>
</div>
<Plan/>
    </>
  )
}

export default Layout