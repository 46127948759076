import React,{useEffect} from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Router, Routes, and Route
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Slider2 from './Components/Slider/Slider';
import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import Layout from './Components/Layout/Layout';
import Pricelist from './Components/Pricelist/Pricelist';
import { useLocation } from "react-router-dom";
import Chatbox from "./Components/Chatbox/Chatbox";
import Footer2 from "./Components/Footer/Footer2";
import Privacypolicy from "./Components/Privacypolicy/Privacypolicy";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  
    });
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/slider" element={<Slider2 />} /> 
        <Route path="/about" element={<About />} /> 
        <Route path="/layout" element={<Layout />} /> 
        <Route path="/pricelist" element={<Pricelist/>} /> 
        <Route path="/privacy" element={<Privacypolicy/>} /> 
      </Routes>
      <Chatbox/>
     <Footer2/>
    </Router>
  );
}

export default App;
