import React from 'react'
import '../Pricelist/Pricelist.css'
import Price from '../Price/Price'
const Pricelist = () => {
  return (
    <>
<div className="poster-section">
        <img src="slide2.jpg" alt="Poster" className="poster-image" />
      </div>
      <div className='pr'>
      <Price/>
      </div>
    </>
  )
}

export default Pricelist