import React from "react";
import "../Text/Text.css";

const Text = () => {
  const content = [
    "Experience a stay in the lap of greenery, strategically equipped for easy access to everything, so that you find all the essesntials for an uninterrupted and effortless living.",
    "JKG Palm Court is designed to utilize sunlight, air, water, & power in the most efficient manner thereby resulting in saving in operational costs. The project has been certified by Indian Green Building Council (IGBC).",
    "At Palm Court, we have strived to create your own personal heaven, to confirm to your needs and soothe your senses, so that, life here will be refreshing & rejoicing and would truly make you feel healed to the core.",
    "Located at the Greater Noida (West), in Palm court, a world of Unbeatable Luxury & unforeseen commitment to quality & comfort, awaits you. ",
    " Our Luxurious apartments area awash with all the comtemporary modern facilities & amenities that go into making life a wonderful experience & a satisfying one.",
   

  ];

  return (
    <div className="luxury-homes-section">
     <h2 className="section-heading">JKG PALM COURT - Greater Noida (West)</h2>
      <div className="container">
        <div className="heading-container">
         <img src="text1.jpg" className="text-img" alt="image"/>
        </div>
        <div className="text-container">
          {content.map((paragraph, index) => (
            <p key={index} className="section-text">{paragraph}</p>
          ))}
          <p className="section-text">

JKG PALM COURT - Greater Noida (West) RERA Registration No: Phase One - UPRERAPRJ6966 | Phase Two - UPRERAPRJ7088 available at:{" "}
            <a href="http://up-rera.in" target="_blank" rel="noopener noreferrer">
              http://up-rera.in
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Text;
