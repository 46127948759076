import React from 'react';
import '../Runner/Runner.css';
import { GiHouseKeys } from "react-icons/gi";

const Runner = () => {
  const texts = [
    "Ready To Move In Flats",
    "Ready To Move In Flats",
    "Ready To Move In Flats",
    "Ready To Move In Flats"
  ];

  return (
    <div className="runner-container">
      <div className="runner-content">
        {/* Duplicate the text array for seamless scrolling */}
        {texts.map((text, index) => (
          <span key={index}>
            {text} <GiHouseKeys className='ic-run'/>&nbsp;
          </span>
        ))}
        {texts.map((text, index) => (
          <span key={index + texts.length}>
            {text} <GiHouseKeys className='ic-run'/>&nbsp;
          </span>
        ))}
      </div>
    </div>
  );
};

export default Runner;
