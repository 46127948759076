import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Plan/Plan.css'; 

const plansData = [
  'f1.jpg',
  'f2.jpg',
  'f3.jpg',
  'f4.jpg',
  'f5.jpg',
  'f6.jpg',
  'f7.jpg',
  
];

const Plan = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="plans-section">
      <div className="plan-l">
        <h2>Floor Plans</h2>
        <Slider {...settings} className="floor-plans-carousel">
          {plansData.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Floor Plan ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="plan-r">
        <h2>Master Plan</h2>
        <img src="master.jpg" alt="Master Plan" className="master-plan-img" />
      </div>
    </div>
  );
};

export default Plan;
