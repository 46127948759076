import React from "react";
import "../About/About.css";
import Text from "../Text/Text";
import Plan from "../Plan/Plan";
import Details from "../Details/Details";
import { FaHandPointRight } from "react-icons/fa";

const About = () => {
  const leftPoints = [
    "Experience a stay in the lap of greenery with easy access to essentials for an uninterrupted living.",
    "Strategically located in Greater Noida (West), offering unbeatable luxury and comfort.",
    "Certified by Indian Green Building Council (IGBC) for sustainable design and operations.",
    "Apartments designed to efficiently use sunlight, air, water, and power, reducing operational costs.",
    "RERA Registration No: Phase One - UPRERAPRJ6966 | Phase Two - UPRERAPRJ7088 available at: http://up-rera.in",
    "Luxurious apartments awash with modern facilities and amenities for a wonderful living experience.",
  ];

  const rightPoints = [
    "Eco-friendly design focusing on sustainable energy use and efficient resource management.",
    "Personal heaven designed to soothe your senses and make life refreshing and rejuvenating.",
    "Conveniently located near essential services and modern facilities in Greater Noida (West).",
    "A perfect blend of modern architecture and serene green surroundings for a balanced lifestyle.",
    "Unmatched luxury and commitment to quality and comfort for an elevated living experience.",
    "Designed to offer both contemporary living and a serene environment that heals the soul.",
  ];

  return (
    <>
      <div className="poster-section2">
        <img src="po.jpg" alt="Poster" className="poster-image2" />
      </div>
      <div className="about-section">
        <h2 className="section-heading22">JKG Palm Court - Greater Noida (West)</h2>
        <div className="about-content">
          <div className="left-ab">
            <ul className="about-list">
              {leftPoints.map((point, index) => (
                <li key={index} className="about-list-item">
                  <span className="about-icon">
                    <FaHandPointRight className="iconar" />
                  </span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
          <div className="right-ab">
            <ul className="about-list">
              {rightPoints.map((point, index) => (
                <li key={index} className="about-list-item">
                  <span className="about-icon">
                    <FaHandPointRight className="iconar" />
                  </span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Details />
      <Plan />
    </>
  );
};

export default About;
