import React from 'react';
import Slider2 from '../Slider/Slider';
import Text from '../Text/Text';
import Cards from '../Cards/Cards';
import Details from '../Details/Details';
import Gallery from '../Gallery/Gallery';
import Price from '../Price/Price';
import Raw from '../Raw/Raw';
import Plan from '../Plan/Plan';
import Location from '../Location/Location';
import Popup from '../Popup/Popup'; // Import the Popup component
import Runner from '../Runner/Runner';

const Home = () => {
  return (
    <>
    
      <Runner/>
      <Slider2 />
      <Text />
      <Cards />
      <Details />
      <Gallery />
      <Price />
      <Raw />
      <Plan />
      <Location />
    
    </>
  );
};

export default Home;
